import React from "react";

import { LocaleProvider } from "./src/context/LocaleContext";
import { MenuProvider } from "./src/context/MenuContext";

export const wrapRootElement = ({ element }) => {
  return (
    <LocaleProvider>
      <MenuProvider>{element}</MenuProvider>
    </LocaleProvider>
  );
};
