import React, { Component } from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";

const defaultState = {
  locale: "en",
  toggleLocale: () => {},
};

const LocaleContext = React.createContext(defaultState);

class LocaleProvider extends Component {
  state = {
    locale: "en",
  };

  toggleLocale = detectedLocale => {
    console.log("toggleLocale");
    const changeToLocale = detectedLocale === "en" ? "ja" : "en";
    changeLocale(changeToLocale);
    this.setState({ locale: changeToLocale });
  };

  componentDidMount() {}

  render() {
    const { children } = this.props;
    const { locale } = this.state;
    return (
      <LocaleContext.Provider
        value={{ locale, toggleLocale: this.toggleLocale }}>
        {children}
      </LocaleContext.Provider>
    );
  }
}

export default LocaleContext;
export { LocaleProvider };
